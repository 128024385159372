import { initLogger } from '@kivra/sdk/log';
import type { Config } from '../types';

export function initSentry(config: Config): void {
  const enabled = window.location.port === '';

  initLogger({
    dsn: config.sender_portal_sentry_dsn,
    enabled,
    environment: process.env.NODE_ENV,
  });
}
