import type { HeimdallQueryParameters } from '../sdk/authentication/types/heimdall';

export class HeimdallQueryParametersUtil {
  heimdallQueryParameters: HeimdallQueryParameters | null;

  constructor() {
    this.heimdallQueryParameters = null;
  }

  getHeimdallQueryParameters = (): HeimdallQueryParameters => {
    if (!this.heimdallQueryParameters) {
      throw new Error(
        'getHeimdallQueryParameters used before HeimdallQueryParametersUtil was initialized with heimdallQueryParameters'
      );
    }
    return this.heimdallQueryParameters;
  };

  clearHeimdallQueryParameters = (): void =>
    this.setHeimdallQueryParameters({});

  setHeimdallQueryParameters = (
    heimdallQueryParameters: HeimdallQueryParameters
  ): void => {
    this.heimdallQueryParameters = heimdallQueryParameters;
  };
}
