import { StyleException, styled } from '@kivra/react-components';
import { AuthenticationBoundary } from '@sender-portal-fe/util-shared/src/routing/AuthenticationBoundary';
import React from 'react';
import { Switch } from 'react-router-dom';

import { NAV_DRAWER_WIDTH } from '@kivra/react-components/src/components/navDrawer/constants.ts';
import { SenderPortalApolloProvider } from '@sender-portal-fe/util-shared/src/components/SenderPortalApolloProvider';
import { LoggedInUserProvider } from '@sender-portal-fe/util-shared/src/login';
import { AccessDenied } from '../components/AccessDenied';
import { PortalNavDrawer } from '../components/NavDrawer/PortalNavDrawer';
import { PortalTopBar } from '../components/TopBar/PortalTopBar';
import { VerticalLayout } from '../components/VerticalLayout';
import { OrganizationProvider } from '../context/OrganizationProvider';
import { getConfig } from '../util/appOptions';
import { ModuleRoutes } from './ModuleRoutes';

export const PrivateRoutes = (): React.JSX.Element => {
  const config = getConfig();
  const { sender_backoffice_admin_organization_id } = config;
  return (
    <AuthenticationBoundary>
      <LoggedInUserProvider
        adminOrganizationId={sender_backoffice_admin_organization_id}
        AccessDeniedComponent={AccessDenied}
      >
        <SenderPortalApolloProvider config={config}>
          <OrganizationProvider>
            <VerticalLayout
              top={({ isSidebarOpen, setIsSidebarOpen }) => (
                <PortalTopBar
                  isMenuOpen={isSidebarOpen}
                  setIsMenuOpen={setIsSidebarOpen}
                />
              )}
              body={({ isSidebarOpen, setIsSidebarOpen }) => (
                <>
                  <PortalNavDrawer
                    isOpen={isSidebarOpen}
                    setIsOpen={setIsSidebarOpen}
                  />
                  <ModuleContainer>
                    <Switch>
                      <ModuleRoutes />
                    </Switch>
                  </ModuleContainer>
                </>
              )}
            />
          </OrganizationProvider>
        </SenderPortalApolloProvider>
      </LoggedInUserProvider>
    </AuthenticationBoundary>
  );
};

const ModuleContainer = styled.main({
  flex: 1,
  position: 'relative',
  minHeight: 'calc(100vh - 100px)',
  paddingLeft: StyleException(NAV_DRAWER_WIDTH),
  $medium: {
    padding: 0,
  },
});
