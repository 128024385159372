import { Flex, RoundedBackground } from '@kivra/react-components';
import { SenderIcon } from '@kivra/react-components/icons';
import React from 'react';

interface Props {
  isActive?: boolean;
  children?: React.ReactNode;
}

export const OrganizationAvatar = ({
  children,
  isActive,
}: Props): React.JSX.Element => {
  return (
    <Flex alignItems="center">
      <RoundedBackground
        size={32}
        backgroundColor={
          isActive ? '$surface-highlight' : '$background-secondary'
        }
      >
        <SenderIcon
          size={16}
          colorToken={isActive ? '$text-black' : '$text-primary'}
        />
      </RoundedBackground>
      {children}
    </Flex>
  );
};
