import {
  KivraTheme,
  useOnMount,
  FullPageLoader,
} from '@kivra/react-components';
import React, { useState } from 'react';
import { getAppOptions } from '@sender-portal-fe/util-shared/src/bootstrap/getAppOptions';
import { Router } from './routes/Router';
import type { Config, Copy } from './types';
import { setAppOptions } from './util/appOptions';
import { bootstrap } from './bootstrap/bootstrap';

export const App = React.memo(() => {
  const [loading, setLoading] = useState(true);
  const [bootError, setBootError] = useState<Error>();

  useOnMount(async () => {
    try {
      const appOptions = await getAppOptions<Copy, Config>();
      setAppOptions(appOptions);
      bootstrap();
      setLoading(false);
    } catch (error) {
      setBootError(error as Error);
    }
  });

  if (bootError) {
    // Will throw and let ErrorBoundary take care of it
    throw bootError;
  }

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <KivraTheme>
      <Router />
    </KivraTheme>
  );
});
