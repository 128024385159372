import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { FixThisAnyLater } from '@kivra/sdk/types/util';

export class CopyUtil<
  Copy extends Record<string, FixThisAnyLater>,
  CopyArgs extends
    | [copyKey: string, CopyArguments: number | Record<string, string | number>]
    | [copyKey: string],
> {
  copy: Copy | null;

  constructor() {
    this.copy = null;
  }

  getCopyObj = (): Copy => {
    if (!this.copy) {
      throw new Error(
        'getCopyObj used before CopyUtil was initialized with copy'
      );
    }
    return this.copy;
  };

  getCopy = (...args: CopyArgs): string => {
    if (!this.copy) {
      throw new Error(
        'getCopyObj used before CopyUtil was initialized with copy'
      );
    }
    return getCopyString<CopyArgs>(this.copy)(...args);
  };

  setCopy = (newCopy: Copy): void => {
    this.copy = newCopy;
  };
}
