import {
  NavDrawer,
  NavDrawerMenu,
  styled,
  useIsMediumScreenSize,
} from '@kivra/react-components';
import type { IconProps } from '@kivra/react-components/icons';
import { pick } from '@kivra/sdk/common';
import { goTo } from '@sender-portal-fe/util-shared/src/routing/history';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useOrganizationState } from '../../context/OrganizationProvider';
import { flattenMenuConfig, getMenuConfig } from './navigationConfig';

const NAV_DRAWER_WIDTH = 263;

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

interface MenuItemProps {
  id: string;
  label: string;
  icon: {
    initial: (props: IconProps) => React.JSX.Element;
    selected: (props: IconProps) => React.JSX.Element;
  };
  disabled?: boolean;
  selected?: boolean;
  parentId?: string;
  onClick?: (id: string) => void;
}

export const PortalNavDrawer = ({
  isOpen,
  setIsOpen,
}: Props): React.JSX.Element => {
  const { organizationKey } = useOrganizationState();
  const location = useLocation();

  const menuConfig = getMenuConfig({
    organizationKey,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstMenuItem = menuConfig[0]!;

  const initialMenuItem = firstMenuItem.subMenu
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firstMenuItem.subMenu[0]!.id
    : firstMenuItem.id;

  const [selectedMenuItemId, setSelectedMenuItemId] =
    useState<string>(initialMenuItem);
  const isMediumScreenSize = useIsMediumScreenSize();

  const navDrawerMenuItems: MenuItemProps[] = menuConfig.map(item => {
    return pick(item, ['id', 'label', 'icon', 'subMenu']);
  });

  /**
   * Selects a top level menu item if it has a declared path.
   * Otherwise selects the first child menu item with a declared path.
   */
  const onClick = (id: string): void => {
    const menuItem = menuConfig.find(item => item.id === id);
    const subMenuItem = menuConfig
      .filter(item => item.subMenu)
      .flatMap(item => item.subMenu)
      .find(item => item?.id === id);

    const selectedMenuItem = menuItem ?? subMenuItem;
    const path =
      selectedMenuItem?.getPath?.() ?? menuItem?.subMenu?.[0]?.getPath?.();

    if (!path) {
      throw new Error(`Couldn't find a path for menu item with id: ${id}`);
    }

    goTo(path);
  };

  useEffect(() => {
    const menuConfigFlat = flattenMenuConfig(menuConfig);

    const menuItemFromRoute = menuConfigFlat.find(menuItem => {
      const path = menuItem.getPath?.();
      return path && matchPath(location.pathname, path);
    });

    if (menuItemFromRoute) {
      setSelectedMenuItemId(menuItemFromRoute.id);
    }
  }, [location]);

  return (
    <DrawerWrapper>
      <NavDrawer
        variant={isMediumScreenSize ? 'temporary' : 'permanent'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        transparent={!isMediumScreenSize}
      >
        <div data-test-id="nav-drawer-menu">
          <NavDrawerMenu
            selectedId={selectedMenuItemId}
            menuItems={navDrawerMenuItems}
            onClick={onClick}
            variant="secondary"
          />
        </div>
      </NavDrawer>
    </DrawerWrapper>
  );
};

const DrawerWrapper = styled.div({
  width: NAV_DRAWER_WIDTH,
  $medium: { width: 0 },
});
