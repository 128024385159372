// This file is copied from kivra_fe_sdk repo
import type { HeimdallToken } from '@kivra/sdk/authentication';
import {
  convertPropertyNamesToCamelCase,
  getAndDecodeOption,
  navigate,
  senderRequest,
  serializeQueryParams,
} from '@kivra/sdk/common';
import { createSynchronousStorage } from '@kivra/sdk/storage';
import type { BackendAccessToken } from '@kivra/sdk/types/heimdall/auth';
import { jwtDecode } from 'jwt-decode';
import { pkceChallenge } from './pkce';
import type {
  HeimdallConfig,
  HeimdallErrorCode,
  HeimdallIdTokenBase,
  HeimdallQueryParameters,
} from './types/heimdall';

const storage = createSynchronousStorage<{
  codeVerifier: string;
  state: string;
}>('kv.heimdall_state');

const AUTHORIZE_ENDPOINT = '/v2/oauth2/authorize';
const TOKEN_ENDPOINT = '/v2/oauth2/token';

export function getHeimdallQueryParameters(
  href: string
): HeimdallQueryParameters {
  return {
    authorizationCode: getAndDecodeOption('code', href),
    redirectState: getAndDecodeOption('state', href),
    error: getAndDecodeOption<HeimdallErrorCode>('error', href),
  };
}

export interface HeimdallAuthentication<ApplicationState> {
  /**
   * Request a authorization code.
   */
  initiateLogin(applicationState?: ApplicationState): Promise<void>;
  /**
   * Exchange authorization code for a token.
   *
   * @param code - authorization code retrieved from redirect caused by initiateLogin
   * @param state - redirect state retrieved from redirect caused by initiateLogin
   */
  getToken(
    code: string,
    state: string
  ): Promise<
    | {
        token: HeimdallToken;
        applicationState?: ApplicationState;
        error: undefined;
      }
    | { token: undefined; applicationState?: ApplicationState; error: Error }
  >;
}

/**
 * Authentication with Heimdall is done in two steps.
 * 1. Request a authorization code by calling initiateLogin().
 * 2. Exchange the authorization code for a token by calling getToken(code, state).
 *
 * In step one the browser will navigate away from the application.
 * The authorization code and state (also needed in step 2) will be returned as
 * query parameters on the specified redirect url.
 */
export const createHeimdallAuthentication = <ApplicationState = object>(
  config: HeimdallConfig
): HeimdallAuthentication<ApplicationState> => {
  return {
    async initiateLogin(applicationState) {
      const { clientId, heimdallUrl, redirectUrl } = config;

      const { codeVerifier, codeChallenge } = await pkceChallenge();
      const state = generateState(applicationState);

      storage.set({ codeVerifier, state });

      const queryParams = {
        client_id: clientId,
        response_type: 'code',
        redirect_uri: redirectUrl,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        scope: 'openid email profile',
        state,
      };

      const queryString = serializeQueryParams(queryParams);

      const loginUrl = `${heimdallUrl}${AUTHORIZE_ENDPOINT}${queryString}`;
      navigate(loginUrl);
    },

    async getToken(code, state) {
      const { clientId, redirectUrl } = config;

      let applicationState: ApplicationState;
      try {
        applicationState = parseApplicationState<ApplicationState>(state);
      } catch {
        return { error: new Error('unable to parse application state') };
      }

      const { codeVerifier, state: currentState } = storage.get() || {};

      if (!codeVerifier) {
        return { error: new Error('no code verifier found'), applicationState };
      }

      if (!currentState || state !== currentState) {
        return { error: new Error('could not verify state'), applicationState };
      }
      storage.remove();

      const requestBody = {
        client_id: clientId,
        grant_type: 'authorization_code',
        code,
        code_verifier: codeVerifier,
        redirect_uri: redirectUrl,
      };

      const response = await senderRequest.post<BackendAccessToken, false>({
        path: TOKEN_ENDPOINT,
        payload: requestBody,
        throwOnError: false,
      });

      if (response.error) {
        return { error: response.error, applicationState };
      }

      return {
        token: convertPropertyNamesToCamelCase(response.body),
        applicationState,
      };
    },
  };
};

export const generateState = <ApplicationState>(
  applicationState?: ApplicationState
): string => {
  const redirectState = String((Math.random() * 1000000000) | 0);
  const state = {
    redirectState,
    applicationState,
  };
  return btoa(JSON.stringify(state));
};

export const parseApplicationState = <ApplicationState>(
  state: string
): ApplicationState => JSON.parse(atob(state)).applicationState;

export const decodeIdToken = <Token extends HeimdallIdTokenBase>(
  encodedToken: string
): Token => {
  return jwtDecode<Token>(encodedToken);
};
