import { useIsMediumScreenSize } from '@kivra/react-components';
import React, { useEffect, useState } from 'react';

type ChildProps = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
};

type Props = {
  top: (props: ChildProps) => React.JSX.Element;
  body: (props: ChildProps) => React.JSX.Element;
};

export const VerticalLayout = ({ top, body }: Props): React.JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMediumScreenSize = useIsMediumScreenSize();
  useEffect(() => setIsSidebarOpen(!isMediumScreenSize), [!isMediumScreenSize]);
  return (
    <>
      {top({ isSidebarOpen, setIsSidebarOpen })}
      {body({ isSidebarOpen, setIsSidebarOpen })}
    </>
  );
};
