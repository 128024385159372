import React, { useEffect, useState } from 'react';
import { useOrganizationState } from '../context/OrganizationProvider';

interface Props {
  callback: (organizationKey: string) => void;
  children?: React.ReactNode;
}

export const OnOrganizationSwap = ({
  callback,
  children,
}: Props): React.JSX.Element => {
  const { organizationKey } = useOrganizationState();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isFirstRender) {
      callback(organizationKey);
    } else {
      setIsFirstRender(false);
    }
  }, [organizationKey]);

  return <>{children}</>;
};
