import { Body, Heading, styled } from '@kivra/react-components';
import type { PictogramType } from '@kivra/react-components/src/components/pictogram/pictograms/index.ts';
import type { ReactElement } from 'react';
import React from 'react';

type Props = {
  pictogramIcon?: ReactElement<PictogramType>;
  title?: string;
  body?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

export const ZeroState = ({
  pictogramIcon,
  title,
  body,
  children,
  ...rest
}: Props): React.JSX.Element => {
  return (
    <Wrapper {...rest} data-component-type="ZeroState">
      {pictogramIcon && <IconWrapper>{pictogramIcon}</IconWrapper>}
      <TextWrapper>
        {title && <StyledHeading size="medium">{title}</StyledHeading>}
        {body && (
          <Body size="small" align="center">
            {body}
          </Body>
        )}
      </TextWrapper>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: 'grid',
  gap: '$spacing-32',
  justifyItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  $small: {
    gap: '$spacing-24',
  },
});

const IconWrapper = styled.div({
  marginBottom: '-$spacing-8', // compensate for padding being included in the pictogram graphic
});

const TextWrapper = styled.div({
  display: 'grid',
  gap: '$spacing-12',
  '> * + *': {
    marginTop: '$spacing-12',
  },
});

const StyledHeading = styled(Heading)({
  margin: 0,
});
