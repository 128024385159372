import { getPathSegmentAfter, sortBy } from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FullPageLoader } from '@kivra/react-components';
import { ZeroState } from '@sender-portal-fe/util-shared/src/components/ZeroState';
import { getPortalError } from '@sender-portal-fe/util-shared/src/util/portalError';
import { getStoredOrganizationKey } from '@sender-portal-fe/util-shared/src/routing/storage';
import { useLoggedInUser } from '@sender-portal-fe/util-shared/src/login';
import type { UserOrganizationsQuery } from '../__generated__/graphql';
import { useUserOrganizationsQuery } from '../__generated__/graphql';
import { AccessDenied } from '../components/AccessDenied';
import { getConfig, getCopy } from '../util/appOptions';

type OrganizationContext = {
  availableOrganizations: UserOrganizationsQuery['organizations'];
  organizationKey: string;
  setOrganization: (key: string) => void;
};

const OrganizationContext = createContext<OrganizationContext>({
  availableOrganizations: [],
  organizationKey: 'organizationKey',
  setOrganization: () => {
    console.warn('setOrganization not implemented');
  },
});

export const useOrganizationState = (): OrganizationContext =>
  useContext(OrganizationContext);

interface Props {
  children: React.ReactNode;
}

export const OrganizationProvider = ({
  children,
}: Props): React.JSX.Element => {
  const { organizationIds } = useLoggedInUser();
  const { sender_backoffice_admin_organization_id } = getConfig();
  const [organizations, setOrganizations] = useState<
    UserOrganizationsQuery['organizations']
  >([]);
  const [organizationKey, setOrganizationKey] = useState<string>();
  const availableOrganizations = organizationIds.filter(
    key => key !== sender_backoffice_admin_organization_id
  );

  const { data, error } = useUserOrganizationsQuery({
    variables: {
      organizationKeys: availableOrganizations,
    },
  });

  const getCurrentOrganizationId = (
    organizations: UserOrganizationsQuery['organizations']
  ): string => {
    return (
      getPathSegmentAfter(location.pathname, 'organization') ||
      getStoredOrganizationKey(organizations.map(o => o.key)) ||
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organizations[0]!.key
    );
  };

  useEffect(() => {
    if (data?.organizations && data.organizations.length > 0) {
      const sortedOrganizations = sortBy(data.organizations, org => org.name);
      setOrganizations(sortedOrganizations);
      setOrganizationKey(getCurrentOrganizationId(sortedOrganizations));
    }
  }, [data]);

  if (availableOrganizations.length === 0) {
    return <AccessDenied />;
  }

  if (error) {
    captureException(error);
    return <ZeroState {...getPortalError({ getCopy })} />;
  }

  if (!data || !organizationKey) {
    return <FullPageLoader position="absolute" />;
  }

  return (
    <OrganizationContext.Provider
      value={{
        availableOrganizations: organizations,
        organizationKey,
        setOrganization: setOrganizationKey,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
