import React, { useState } from 'react';
import { Button, FullPageLoader, useOnMount } from '@kivra/react-components';
import { history } from '@sender-portal-fe/util-shared/src/routing/history';
import { login } from '@sender-portal-fe/util-shared/src/login/login';
import { captureException } from '@kivra/sdk/log';
import { ZeroState } from '@sender-portal-fe/util-shared/src/components/ZeroState';
import { getPortalError } from '@sender-portal-fe/util-shared/src/util/portalError';
import type { FixThisAnyLater } from '@kivra/sdk/types/util';
import { navigate } from '@kivra/sdk/common';
import {
  getConfig,
  getCopy,
  getHeimdallQueryParameters,
  clearHeimdallQueryParameters,
} from '../util/appOptions';

export const LoginPage = (): React.JSX.Element => {
  const [hasError, setHasError] = useState(false);

  useOnMount(async () => {
    try {
      await login({
        config: getConfig(),
        heimdallQueryParameters: getHeimdallQueryParameters(),
        onLoginRoute: history.location.state?.onLoginRoute,
      });
      clearHeimdallQueryParameters();
    } catch (error) {
      captureException(error as FixThisAnyLater);
      setHasError(true);
    }
  });

  if (hasError) {
    return (
      <ZeroState
        {...getPortalError({ getCopy, type: 'generic' })}
        title={getCopy('sender_backoffice__error_login_title')}
        body={getCopy('sender_backoffice__error_login_body')}
      >
        <Button onClick={() => navigate(location.origin)}>
          {getCopy('btn__try_again')}
        </Button>
      </ZeroState>
    );
  }

  return <FullPageLoader />;
};
