import type { ComponentProps } from 'react';
import React from 'react';
import { Pictogram } from '@kivra/react-components';
import type { ZeroState } from '../components/ZeroState';
import { getZeroStateProps } from './getZeroStateProps';

export type Pictogram = keyof typeof Pictogram;

type RequiredCopyKeys =
  | 'error__page_not_found'
  | 'error_generic__title'
  | 'sender_portal__error_title_load_content'
  | 'sender_portal__error_title_no_network'
  | 'sender_portal__error_body_generic'
  | 'sender_portal__error_body_no_network'
  | 'sender_portal__error_body_not_found'
  | 'sender_backoffice__access_denied'
  | 'sender_backoffice__access_denied_body';

export type Args = {
  getCopy: (key: RequiredCopyKeys) => string;
  type?: 'generic' | 'load-data' | 'no-network' | 'not-found' | 'access-denied';
  fullPage?: boolean;
};

const pictogramIcons = {
  'broken-heart': <Pictogram.BrokenHeart variant="pink" />,
  'no-network': <Pictogram.NoNetwork variant="indigo" />,
  lock: <Pictogram.Lock variant="indigo" />,
};

export const getPortalError = ({
  getCopy,
  type,
  fullPage = true,
}: Args): ComponentProps<typeof ZeroState> => {
  const style = fullPage ? { height: '70vh' } : undefined;
  const { pictogramIcon, ...rest } = getZeroStateProps({ getCopy, type });
  return { ...rest, style, pictogramIcon: pictogramIcons[pictogramIcon] };
};
