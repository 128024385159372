import React from 'react';
import { ErrorBoundary, Pictogram } from '@kivra/react-components';
import { captureException } from '@kivra/sdk/log';
import { ZeroState } from './ZeroState';

interface Props {
  children?: React.ReactNode;
}

export const SenderPortalErrorBoundary = ({
  children,
}: Props): React.JSX.Element => (
  <ErrorBoundary
    onCatch={error => {
      console.error(error);
      captureException(error);
    }}
    ErrorComponent={() => (
      <ZeroState
        style={{ marginTop: '30vh' }}
        title="Something went wrong"
        body="Please try again. If the problem persists, please contact avsandare.support@kivra.com"
        pictogramIcon={<Pictogram.BrokenHeart variant="pink" />}
      />
    )}
  >
    {children}
  </ErrorBoundary>
);
