import { ZeroState } from '@sender-portal-fe/util-shared/src/components/ZeroState';
import { getPortalError } from '@sender-portal-fe/util-shared/src/util/portalError';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from '../pages/Login';
import { getCopy } from '../util/appOptions';

import { getPath, getRoute } from './routes';

export const PublicRoutes = (): React.JSX.Element => {
  return (
    <Switch>
      <Redirect exact from={getRoute('root')} to={getPath({ id: 'start' })} />
      <Route exact path={getRoute('login')} component={LoginPage} />
      <Route
        path="*"
        component={() => (
          <ZeroState {...getPortalError({ getCopy, type: 'not-found' })} />
        )}
      />
    </Switch>
  );
};
