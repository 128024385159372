import {
  AccountMenu,
  AccountMenuButton,
  MenuItem,
  styled,
} from '@kivra/react-components';
import { LogoutIcon, PersonIcon } from '@kivra/react-components/icons';
import { goTo } from '@sender-portal-fe/util-shared/src/routing/history';
import { revokeToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/revokeToken';
import React, { useEffect, useRef, useState } from 'react';
import { useOrganizationState } from '../../../context/OrganizationProvider';
import { getPath } from '../../../routes/routes';
import { getCopy } from '../../../util/appOptions';
import { OrganizationAvatar } from './OrganizationAvatar';
import { OrganizationPicker } from './OrganizationPicker';

interface Props {
  /** Callback fired when menu is closing */
  onClose: () => void;
  displayName: string;
}

export const PopoverAccountMenu = ({
  displayName,
  onClose,
}: Props): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { organizationKey, availableOrganizations } = useOrganizationState();
  const shouldShowOrganizationPicker = availableOrganizations.length > 1;

  // Tell the parent that the menu is closing
  useEffect(() => {
    onClose();
  }, [isOpen]);

  const handleLogout = async (): Promise<void> => {
    await revokeToken();
    goTo(getPath({ id: 'login' }));
  };

  const currentOrganizationName = availableOrganizations.find(
    ({ key }) => key === organizationKey
  )?.name;

  return (
    <>
      <Nav>
        <AccountMenuButton
          open={isOpen}
          menuId="account-menu-popover"
          data-test-id="AccountMenu"
          aria-label={getCopy('sender_portal__accountmenu_label_screenreaders')}
          onClick={() => setIsOpen(true)}
          ref={anchorRef}
          icon="arrow"
          logo={<OrganizationAvatar isActive />}
          title={currentOrganizationName}
          description={displayName}
        />
      </Nav>
      <AccountMenu
        anchorRef={anchorRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        id="account-menu-popover"
      >
        <MenuItem
          disabled
          overrideDisabledOpacity
          divider
          title={displayName}
          size="small"
          icon={PersonIcon}
          onClick={() => {
            // disabled
          }}
        />
        {shouldShowOrganizationPicker && (
          <OrganizationPicker onClick={() => setIsOpen(false)} autoFocus />
        )}
        <MenuItem
          size="small"
          icon={LogoutIcon}
          title={getCopy('misc_option__logout')}
          onClick={handleLogout}
          autoFocus={!shouldShowOrganizationPicker}
        />
      </AccountMenu>
    </>
  );
};

const Nav = styled.nav({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});
