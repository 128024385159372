import { createBrowserHistory } from 'history';

export interface HistoryState {
  previousRoute?: string;
  onLoginRoute?: string;
}

export const goBack = (fallbackRoute: string): void => {
  const previousRoute = history.location.state?.previousRoute;
  if (previousRoute) {
    history.goBack();
  } else {
    history.push(fallbackRoute);
  }
};

export const goTo = (path: string): void => {
  history.push(path, { previousRoute: history.location.pathname });
};

export const goUp = (): void => {
  goTo(getParentPath());
};

export const getParentPath = (): string =>
  history.location.pathname.split('/').slice(0, -1).join('/');

export const history = createBrowserHistory<HistoryState | undefined>();
