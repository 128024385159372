import type { ComponentProps } from 'react';
import React from 'react';
import { ZeroState } from '@sender-portal-fe/util-shared/src/components/ZeroState';
import { Route } from 'react-router-dom';
import { getPortalError } from '@sender-portal-fe/util-shared/src/util/portalError';
import type { Config } from '../types';
import { getConfig, getCopy } from '../util/appOptions';

type Props = {
  featureFlag?: keyof Config;
} & ComponentProps<typeof Route>;

export const ModuleRoute = ({
  featureFlag,
  children,
  ...routeProps
}: Props): React.JSX.Element => {
  const config = getConfig();
  const isFeatureDisabled = featureFlag && !config[featureFlag];

  return (
    <Route {...routeProps}>
      {isFeatureDisabled ? (
        <ZeroState {...getPortalError({ getCopy, type: 'not-found' })} />
      ) : (
        children
      )}
    </Route>
  );
};
