import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Time: { input: any; output: any; }
};

export enum Alignment {
  center = 'center',
  left = 'left',
  right = 'right'
}

export type Background = {
  __typename: 'Background';
  /** The image that is shown on the header. */
  image?: Maybe<Image>;
  /** The large image that is shown on the header. */
  largeImage?: Maybe<Image>;
  /** The last time the branding background was updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BackgroundColor = {
  __typename: 'BackgroundColor';
  value: Scalars['String']['output'];
};

export type BannerConfig = {
  __typename: 'BannerConfig';
  backgroundColor: BackgroundColor;
  body: TextElement;
  button: ButtonElement;
  title: TextElement;
  version: Scalars['Int']['output'];
};

/**
 * Branding for a Sender. This will be used to style the Sender pages across the
 * applications.
 */
export type Branding = {
  __typename: 'Branding';
  /** The preferred background. */
  background: Background;
  /** The main logo of a sender. */
  logo: Image;
};

export type BrandingBackgroundImageInput = {
  type: ImageInputType;
  value: Scalars['String']['input'];
};

export type ButtonElement = {
  __typename: 'ButtonElement';
  options: ButtonOptions;
  value: Scalars['String']['output'];
};

export type ButtonOptions = {
  __typename: 'ButtonOptions';
  alignment: Alignment;
  backgroundColor: Scalars['String']['output'];
  bold: Scalars['Boolean']['output'];
  color: Scalars['String']['output'];
  font: Scalars['String']['output'];
  italic: Scalars['Boolean']['output'];
  maxChars: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  underline: Scalars['Boolean']['output'];
  visible: Scalars['Boolean']['output'];
};

export type Campaign = {
  __typename: 'Campaign';
  /** The start time for when an campaign is shown for a content */
  activeFrom?: Maybe<Scalars['String']['output']>;
  /** The end time for when an campaign is shown for a content. */
  activeTo?: Maybe<Scalars['String']['output']>;
  /**
   * The start time on which content that will show the campaign. If a content
   * was sent out to a user after this date, the campaign will be shown for that
   * content.
   */
  attachFrom?: Maybe<Scalars['String']['output']>;
  /** The end time on which content that will show the campaign */
  attachTo?: Maybe<Scalars['String']['output']>;
  /** Object describing configuration for generating banner image */
  bannerConfig?: Maybe<BannerConfig>;
  /**
   * The snapshot status on the campaign for when it is returned from backend.
   * Determines if a campaign is: ongoing, upcoming, previous or draft.
   */
  clientStatus: CampaignClientStatus;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  /** An URL that will be opened when a user clicks on the campaign */
  destinationUrl?: Maybe<Scalars['String']['output']>;
  /** Determines which type of documents the campaign will be shown on */
  documentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Base64 png String */
  image?: Maybe<Scalars['String']['output']>;
  imageHeight?: Maybe<Scalars['Int']['output']>;
  imageWidth?: Maybe<Scalars['Int']['output']>;
  /** Metrics from the data team for the campaign */
  metrics?: Maybe<CampaignMetricsResult>;
  /** The organization that owns the campaigns */
  organization: CampaignOrganization;
  /** Determines if the campaign will be shown above or below the content */
  position: CampaignPosition;
  segmentation?: Maybe<Segmentation>;
  segmentationTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** The sender that owns the campaigns */
  sender: CampaignSender;
  /** Status for published/unpublished/canceled */
  status?: Maybe<CampaignStatus>;
  /** Tag used for segmentation of a campaign to certain contents */
  tag?: Maybe<Scalars['String']['output']>;
  /** Determines which target group (private users / company users / both) */
  targetGroup: TargetGroup;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** If SSO is enabled, campaign link will be ignored */
  useSso: Scalars['Boolean']['output'];
};

export enum CampaignClientStatus {
  draft = 'draft',
  ongoing = 'ongoing',
  previous = 'previous',
  upcoming = 'upcoming'
}

export type CampaignMetrics = {
  __typename: 'CampaignMetrics';
  /** How many contents has been attached with a campaign when the content was created/sent */
  attachedToContent: Scalars['Int']['output'];
  /** How many has clicked the campaign that has opened the campaign. clickedByUser/openedByUser */
  clickThroughRate: Scalars['Float']['output'];
  /** How many has clicked the campaign */
  clickedByUser: Scalars['Int']['output'];
  /** How many has opened a content with the campaign attached */
  openedByUser: Scalars['Int']['output'];
};

export enum CampaignMetricsError {
  NOT_FOUND = 'NOT_FOUND',
  UNAVAILABLE_FOR_LEGAL_REASONS = 'UNAVAILABLE_FOR_LEGAL_REASONS'
}

export type CampaignMetricsErrorResponse = {
  __typename: 'CampaignMetricsErrorResponse';
  error: CampaignMetricsError;
};

export type CampaignMetricsResult = CampaignMetrics | CampaignMetricsErrorResponse;

export type CampaignOrganization = {
  __typename: 'CampaignOrganization';
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum CampaignPosition {
  bottom = 'bottom',
  top = 'top'
}

export type CampaignSender = {
  __typename: 'CampaignSender';
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: CampaignSenderType;
};

export enum CampaignSenderType {
  chain = 'chain',
  store = 'store',
  tenant = 'tenant'
}

export enum CampaignStatus {
  canceled = 'canceled',
  published = 'published',
  unpublished = 'unpublished'
}

/** Campaigns related information for a sender */
export type CampaignsDomain = {
  __typename: 'CampaignsDomain';
  /** Fetch a single campaign by id */
  campaign: Campaign;
  /** Fetch campaigns for a sender using ClientStatus */
  campaigns: Array<Campaign>;
  /** Document types that can be attached to campaigns */
  documentTypes: Array<LocalizedLabel>;
};


/** Campaigns related information for a sender */
export type CampaignsDomainCampaignArgs = {
  id: Scalars['String']['input'];
};


/** Campaigns related information for a sender */
export type CampaignsDomainCampaignsArgs = {
  status: CampaignClientStatus;
};

export type ConfigSso = {
  __typename: 'ConfigSso';
  /** Service provider URL */
  serviceProviderUrl?: Maybe<Scalars['String']['output']>;
};

export type ContactField = {
  __typename: 'ContactField';
  key: Scalars['String']['output'];
  /** @deprecated No longer supported */
  label: ContactFieldLabel;
  localizedLabel: ContactLocalizedLabel;
  opts: ContactFieldOpts;
  /** @deprecated No longer supported */
  order: Scalars['Int']['output'];
  type: ContactFieldType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['String']['output'];
  /** @deprecated No longer supported */
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFieldInput = {
  label: ContactFieldLabel;
  opts?: InputMaybe<ContactFieldOptsInput>;
  type: ContactFieldType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export enum ContactFieldLabel {
  CHAT = 'CHAT',
  EMAIL = 'EMAIL',
  FAQ = 'FAQ',
  MY_PAGES = 'MY_PAGES',
  PHONE = 'PHONE',
  TO_THE_APP = 'TO_THE_APP',
  WEBSITE = 'WEBSITE',
  WRITE_TO_US = 'WRITE_TO_US'
}

export type ContactFieldOpts = {
  __typename: 'ContactFieldOpts';
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFieldOptsInput = {
  ssoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ContactFieldType {
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  PHONE = 'PHONE'
}

export type ContactLocalizedLabel = {
  __typename: 'ContactLocalizedLabel';
  key: ContactFieldLabel;
  label: Scalars['String']['output'];
  language: Scalars['String']['output'];
};

export type ContentMetricsResult = {
  __typename: 'ContentMetricsResult';
  daily: Array<SenderMetricsDaily>;
  monthly: Array<SenderMetricsMonthly>;
  total: Array<SenderMetricsTotalRow>;
};


export type ContentMetricsResultDailyArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};


export type ContentMetricsResultMonthlyArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};


export type ContentMetricsResultTotalArgs = {
  combinedTypesSummary?: InputMaybe<Scalars['Boolean']['input']>;
  contentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * from: inclusive
 * to: inclusive
 */
export type DateRange = {
  __typename: 'DateRange';
  from: Scalars['Time']['output'];
  to: Scalars['Time']['output'];
};

/**
 * from: inclusive
 * to: inclusive
 */
export type DateRangeInput = {
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
};

export type ElementOption = {
  __typename: 'ElementOption';
  alignment: Alignment;
  bold: Scalars['Boolean']['output'];
  color: Scalars['String']['output'];
  font: Scalars['String']['output'];
  italic: Scalars['Boolean']['output'];
  maxChars: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  underline: Scalars['Boolean']['output'];
  visible: Scalars['Boolean']['output'];
};

export type FeatureConfig = ConfigSso;

export type Image = {
  __typename: 'Image';
  /** A URL to the image that is publicly accessible. */
  publicUrl: Scalars['String']['output'];
};

/** Format of image input */
export enum ImageInputType {
  /** A base 64 encoded string */
  BASE_64 = 'BASE_64',
  /** No image defined */
  NO_IMAGE = 'NO_IMAGE'
}

export type LocalizedLabel = {
  __typename: 'LocalizedLabel';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  language: Scalars['String']['output'];
};

export type Membership = {
  __typename: 'Membership';
  /** ID of the member */
  id: Scalars['ID']['output'];
  /** Email of the memeber */
  idFieldValue: Scalars['String']['output'];
  /** Identity provider of use */
  idProvider: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  inviteMemberToOrganization: Organization;
  removeMemberFromOrganization: Organization;
  updateSenderProfile: Sender;
};


export type MutationInviteMemberToOrganizationArgs = {
  email: Scalars['String']['input'];
  identityProvider: Scalars['String']['input'];
  organizationKey: Scalars['ID']['input'];
  organizationName: Scalars['String']['input'];
};


export type MutationRemoveMemberFromOrganizationArgs = {
  memberKey: Scalars['ID']['input'];
  organizationKey: Scalars['ID']['input'];
};


export type MutationUpdateSenderProfileArgs = {
  backgroundImage?: InputMaybe<BrandingBackgroundImageInput>;
  contactFields?: InputMaybe<Array<ContactFieldInput>>;
  largeBackgroundImage?: InputMaybe<BrandingBackgroundImageInput>;
  senderKey: Scalars['ID']['input'];
};

export type Organization = {
  __typename: 'Organization';
  /** Timestamp when organization was created */
  createdAt: Scalars['DateTime']['output'];
  /** List of ID Providers for organization */
  idProviders: Array<Scalars['String']['output']>;
  /** Not returned by backend - but should be */
  key: Scalars['ID']['output'];
  /** Members of the organization */
  members: Array<Membership>;
  /** Name of the organization  */
  name: Scalars['String']['output'];
  /** The senders that belong to the organization */
  senders: Array<Sender>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  count: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['Time']['output']>;
  hasNext: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['Time']['output']>;
};

export type PayableContentMetricsResult = {
  __typename: 'PayableContentMetricsResult';
  daily: Array<PayableMetricsDaily>;
  dailyPaymentFlow: PaymentFlowResult;
  monthly: Array<PayableMetricsMonthly>;
  monthlyPaymentFlow: PaymentFlowResult;
};


export type PayableContentMetricsResultDailyArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};


export type PayableContentMetricsResultDailyPaymentFlowArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};


export type PayableContentMetricsResultMonthlyArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};


export type PayableContentMetricsResultMonthlyPaymentFlowArgs = {
  opts?: InputMaybe<SenderMetricsQueryOpts>;
};

export type PayableMetricsDaily = {
  __typename: 'PayableMetricsDaily';
  contentType?: Maybe<Scalars['String']['output']>;
  data: Array<PayableMetricsDailyRow>;
  pageInfo: PageInfo;
  summary: PayableMetricsSummary;
};

export type PayableMetricsDailyRow = {
  __typename: 'PayableMetricsDailyRow';
  amountInvoiced: Scalars['Float']['output'];
  avoidedCo2Emissions: Scalars['Float']['output'];
  date: Scalars['Time']['output'];
  payableContentMarkedAsPaid: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneDay: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinSixtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentPaid: Scalars['Int']['output'];
  payableContentPaidSwish: Scalars['Int']['output'];
  payableContentPaidTink: Scalars['Int']['output'];
  payableContentPaidWithinOneDay: Scalars['Int']['output'];
  payableContentPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentSent: Scalars['Int']['output'];
  payableContentViewed: Scalars['Int']['output'];
  payableContentViewedWithinOneDay: Scalars['Int']['output'];
  payableContentViewedWithinOneWeek: Scalars['Int']['output'];
  payableContentViewedWithinThirtyDays: Scalars['Int']['output'];
  payableContentViewedWithinTwoHours: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneDay: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneWeek: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinSixtyDays: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinThirtyDays: Scalars['Int']['output'];
  savedTrees: Scalars['Float']['output'];
};

export type PayableMetricsMonthly = {
  __typename: 'PayableMetricsMonthly';
  contentType?: Maybe<Scalars['String']['output']>;
  data: Array<PayableMetricsMonthlyRow>;
  pageInfo: PageInfo;
  summary: PayableMetricsSummary;
};

export type PayableMetricsMonthlyRow = {
  __typename: 'PayableMetricsMonthlyRow';
  amountInvoiced: Scalars['Float']['output'];
  avoidedCo2Emissions: Scalars['Float']['output'];
  month: Scalars['Time']['output'];
  payableContentMarkedAsPaid: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneDay: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinSixtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentPaid: Scalars['Int']['output'];
  payableContentPaidSwish: Scalars['Int']['output'];
  payableContentPaidTink: Scalars['Int']['output'];
  payableContentPaidWithinOneDay: Scalars['Int']['output'];
  payableContentPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentSent: Scalars['Int']['output'];
  payableContentViewed: Scalars['Int']['output'];
  payableContentViewedWithinOneDay: Scalars['Int']['output'];
  payableContentViewedWithinOneWeek: Scalars['Int']['output'];
  payableContentViewedWithinThirtyDays: Scalars['Int']['output'];
  payableContentViewedWithinTwoHours: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneDay: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneWeek: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinSixtyDays: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinThirtyDays: Scalars['Int']['output'];
  savedTrees: Scalars['Float']['output'];
};

export type PayableMetricsSummary = {
  __typename: 'PayableMetricsSummary';
  amountInvoiced: Scalars['Float']['output'];
  avoidedCo2Emissions: Scalars['Float']['output'];
  payableContentMarkedAsPaid: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneDay: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinSixtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentMarkedAsPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentPaid: Scalars['Int']['output'];
  payableContentPaidSwish: Scalars['Int']['output'];
  payableContentPaidTink: Scalars['Int']['output'];
  payableContentPaidWithinOneDay: Scalars['Int']['output'];
  payableContentPaidWithinOneWeek: Scalars['Int']['output'];
  payableContentPaidWithinThirtyDays: Scalars['Int']['output'];
  payableContentPaidWithinTwoHours: Scalars['Int']['output'];
  payableContentSent: Scalars['Int']['output'];
  payableContentViewed: Scalars['Int']['output'];
  payableContentViewedWithinOneDay: Scalars['Int']['output'];
  payableContentViewedWithinOneWeek: Scalars['Int']['output'];
  payableContentViewedWithinThirtyDays: Scalars['Int']['output'];
  payableContentViewedWithinTwoHours: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneDay: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinOneWeek: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinSixtyDays: Scalars['Int']['output'];
  paymentSuccesfullyTransferredWithinThirtyDays: Scalars['Int']['output'];
  savedTrees: Scalars['Float']['output'];
};

export type PaymentFlowItem = {
  __typename: 'PaymentFlowItem';
  daysToAction: Scalars['String']['output'];
  receiveToAct: Scalars['Int']['output'];
  receiveToOpen: Scalars['Int']['output'];
  receiveToTransact: Scalars['Int']['output'];
};

export type PaymentFlowResult = {
  __typename: 'PaymentFlowResult';
  averages: PaymentFlowResultAverages;
  byContentType: Array<PaymentFlowResultByContentType>;
  summary: PaymentFlowResultSummary;
};

export type PaymentFlowResultAverages = {
  __typename: 'PaymentFlowResultAverages';
  receiveToAct?: Maybe<Scalars['Int']['output']>;
  receiveToOpen?: Maybe<Scalars['Int']['output']>;
  receiveToTransact?: Maybe<Scalars['Int']['output']>;
};

export type PaymentFlowResultByContentType = {
  __typename: 'PaymentFlowResultByContentType';
  contentType: Scalars['String']['output'];
  data: Array<PaymentFlowItem>;
};

export type PaymentFlowResultSummary = {
  __typename: 'PaymentFlowResultSummary';
  data: Array<PaymentFlowItem>;
};

export type Query = {
  __typename: 'Query';
  organization: Organization;
  organizations: Array<Organization>;
  sender: Sender;
  system?: Maybe<SystemInfo>;
};


export type QueryOrganizationArgs = {
  key: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  keys: Array<Scalars['ID']['input']>;
};


export type QuerySenderArgs = {
  key: Scalars['ID']['input'];
};

export type Segmentation = {
  __typename: 'Segmentation';
  /** Key of the campaign for the segmentation */
  campaignKey?: Maybe<Scalars['String']['output']>;
  /** Name of the original segmentation file */
  filename: Scalars['String']['output'];
  /** Key of the job that is processing the segmentation file */
  jobKey?: Maybe<Scalars['String']['output']>;
  /** Job progress in permille */
  progressPermille?: Maybe<Scalars['Int']['output']>;
  /** Amount of rows (ssn) of the segmentation file */
  rows: Scalars['Int']['output'];
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status: SegmentationStatus;
  /** Path to the job that is processing the segmentation file */
  statusPath?: Maybe<Scalars['String']['output']>;
  uploadedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SegmentationStatus {
  canceled = 'canceled',
  completed = 'completed',
  error = 'error',
  ongoing = 'ongoing'
}

export type Sender = {
  __typename: 'Sender';
  /** Sender branding information */
  branding: Branding;
  /** Entry point to sender campaigns domain which needs organization key to be provided */
  campaignsDomain: CampaignsDomain;
  /** Public information used for contacting a sender. */
  contactFields: Array<ContactField>;
  /** Sender specific features */
  features: Array<SenderFeature>;
  key: Scalars['ID']['output'];
  metrics: SenderMetrics;
  /** Sender name */
  name: Scalars['String']['output'];
  /** Type of sender */
  type: SenderType;
};


export type SenderCampaignsDomainArgs = {
  organizationKey: Scalars['ID']['input'];
};

/** Sender feature configuration */
export type SenderFeature = {
  __typename: 'SenderFeature';
  /** Feature specific configuration */
  config: FeatureConfig;
  /** Whether the feature is enabled */
  enabled: Scalars['Boolean']['output'];
  /** Feature name */
  feature: Scalars['String']['output'];
  /** Last update time */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SenderMetrics = {
  __typename: 'SenderMetrics';
  content: ContentMetricsResult;
  meta: SenderMetricsMeta;
  payableContent: PayableContentMetricsResult;
};

export type SenderMetricsDaily = {
  __typename: 'SenderMetricsDaily';
  contentType?: Maybe<Scalars['String']['output']>;
  data: Array<SenderMetricsDailyRow>;
  pageInfo: PageInfo;
  summary: SenderMetricsSummary;
};

export type SenderMetricsDailyRow = {
  __typename: 'SenderMetricsDailyRow';
  avoidedCo2Emissions: Scalars['Float']['output'];
  contentNonPayableSent: Scalars['Int']['output'];
  contentNonPayableViewed: Scalars['Int']['output'];
  contentPaid: Scalars['Int']['output'];
  contentPaidWithinOneDay: Scalars['Int']['output'];
  contentPaidWithinOneWeek: Scalars['Int']['output'];
  contentPaidWithinThirtyDays: Scalars['Int']['output'];
  contentPaidWithinTwoHours: Scalars['Int']['output'];
  contentPayableSent: Scalars['Int']['output'];
  contentPayableViewed: Scalars['Int']['output'];
  contentSent: Scalars['Int']['output'];
  contentViewed: Scalars['Int']['output'];
  contentViewedWithinOneDay: Scalars['Int']['output'];
  contentViewedWithinOneWeek: Scalars['Int']['output'];
  contentViewedWithinThirtyDays: Scalars['Int']['output'];
  contentViewedWithinTwoHours: Scalars['Int']['output'];
  date: Scalars['Time']['output'];
  savedTrees: Scalars['Float']['output'];
  uniqueRecipients?: Maybe<Scalars['Int']['output']>;
};

export type SenderMetricsMeta = {
  __typename: 'SenderMetricsMeta';
  availableContentTypes?: Maybe<Array<Scalars['String']['output']>>;
  availablePayableContentTypes?: Maybe<Array<Scalars['String']['output']>>;
  earliestAvailableDate?: Maybe<Scalars['Time']['output']>;
  lastUpdated?: Maybe<Scalars['Time']['output']>;
  latestAvailableDate?: Maybe<Scalars['Time']['output']>;
};

export type SenderMetricsMonthly = {
  __typename: 'SenderMetricsMonthly';
  contentType?: Maybe<Scalars['String']['output']>;
  data: Array<SenderMetricsMonthlyRow>;
  pageInfo: PageInfo;
  summary: SenderMetricsSummary;
};

export type SenderMetricsMonthlyRow = {
  __typename: 'SenderMetricsMonthlyRow';
  avoidedCo2Emissions: Scalars['Float']['output'];
  contentNonPayableSent: Scalars['Int']['output'];
  contentNonPayableViewed: Scalars['Int']['output'];
  contentPaid: Scalars['Int']['output'];
  contentPaidWithinOneDay: Scalars['Int']['output'];
  contentPaidWithinOneWeek: Scalars['Int']['output'];
  contentPaidWithinThirtyDays: Scalars['Int']['output'];
  contentPaidWithinTwoHours: Scalars['Int']['output'];
  contentPayableSent: Scalars['Int']['output'];
  contentPayableViewed: Scalars['Int']['output'];
  contentSent: Scalars['Int']['output'];
  contentViewed: Scalars['Int']['output'];
  contentViewedWithinOneDay: Scalars['Int']['output'];
  contentViewedWithinOneWeek: Scalars['Int']['output'];
  contentViewedWithinThirtyDays: Scalars['Int']['output'];
  contentViewedWithinTwoHours: Scalars['Int']['output'];
  month: Scalars['Time']['output'];
  savedTrees: Scalars['Float']['output'];
  uniqueRecipients?: Maybe<Scalars['Int']['output']>;
};

export type SenderMetricsQueryOpts = {
  combinedTypesSummary?: InputMaybe<Scalars['Boolean']['input']>;
  contentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  cursor?: InputMaybe<Scalars['Time']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<DateRangeInput>;
  sort?: InputMaybe<Sort>;
};

export type SenderMetricsSummary = {
  __typename: 'SenderMetricsSummary';
  avoidedCo2Emissions: Scalars['Float']['output'];
  contentNonPayableSent: Scalars['Int']['output'];
  contentNonPayableViewed: Scalars['Int']['output'];
  contentPaid: Scalars['Int']['output'];
  contentPaidWithinOneDay: Scalars['Int']['output'];
  contentPaidWithinOneWeek: Scalars['Int']['output'];
  contentPaidWithinThirtyDays: Scalars['Int']['output'];
  contentPaidWithinTwoHours: Scalars['Int']['output'];
  contentPayableSent: Scalars['Int']['output'];
  contentPayableViewed: Scalars['Int']['output'];
  contentSent: Scalars['Int']['output'];
  contentViewed: Scalars['Int']['output'];
  contentViewedWithinOneDay: Scalars['Int']['output'];
  contentViewedWithinOneWeek: Scalars['Int']['output'];
  contentViewedWithinThirtyDays: Scalars['Int']['output'];
  contentViewedWithinTwoHours: Scalars['Int']['output'];
  savedTrees: Scalars['Float']['output'];
};

export type SenderMetricsTotalRow = {
  __typename: 'SenderMetricsTotalRow';
  avoidedCo2Emissions: Scalars['Float']['output'];
  contentNonPayableSent: Scalars['Int']['output'];
  contentOpened: Scalars['Int']['output'];
  contentPayablePaid: Scalars['Int']['output'];
  contentPayableSent: Scalars['Int']['output'];
  contentSent: Scalars['Int']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  savedTrees: Scalars['Float']['output'];
};

export enum SenderType {
  chain = 'chain',
  store = 'store',
  tenant = 'tenant'
}

/** Sort, specifies how to sort response, only applicable where time is present. */
export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SystemInfo = {
  __typename: 'SystemInfo';
  /** Server version */
  version: Scalars['String']['output'];
};

export enum TargetGroup {
  all = 'all',
  company = 'company',
  user = 'user'
}

export type TextElement = {
  __typename: 'TextElement';
  options: ElementOption;
  value: Scalars['String']['output'];
};

export type UserOrganizationsQueryVariables = Exact<{
  organizationKeys: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type UserOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', key: string, name: string }> };


export const UserOrganizationsDocument = gql`
    query userOrganizations($organizationKeys: [ID!]!) {
  organizations(keys: $organizationKeys) {
    key
    name
  }
}
    `;

/**
 * __useUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationsQuery({
 *   variables: {
 *      organizationKeys: // value for 'organizationKeys'
 *   },
 * });
 */
export function useUserOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables> & ({ variables: UserOrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, options);
      }
export function useUserOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, options);
        }
export function useUserOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, options);
        }
export type UserOrganizationsQueryHookResult = ReturnType<typeof useUserOrganizationsQuery>;
export type UserOrganizationsLazyQueryHookResult = ReturnType<typeof useUserOrganizationsLazyQuery>;
export type UserOrganizationsSuspenseQueryHookResult = ReturnType<typeof useUserOrganizationsSuspenseQuery>;
export type UserOrganizationsQueryResult = Apollo.QueryResult<UserOrganizationsQuery, UserOrganizationsQueryVariables>;