import type { IconProps } from '@kivra/react-components/icons';
import {
  ChartIcon,
  PhonebookFilledIcon,
  PhonebookIcon,
  SettingsFilledIcon,
  SettingsIcon,
  StarFilledIcon,
  StarIcon,
} from '@kivra/react-components/icons';
import { getPath as metricsGetPath } from '@sender-portal-fe/module-metrics/routes';
import { getPath as senderHomeGetPath } from '@sender-portal-fe/module-sender-home/routes';
import { getPath as teamManagementGetPath } from '@sender-portal-fe/module-team-management/routes';
import { CAMPAIGNS_BASE_PATH } from '../../routes/Router';
import type { Config } from '../../types';
import { getConfig, getCopy } from '../../util/appOptions';

export type MenuConfigItem = {
  /** Must be unique for every menu item */
  id: string;
  icon: {
    initial: (props: IconProps) => React.JSX.Element;
    selected: (props: IconProps) => React.JSX.Element;
  };
  /** If a parent menu item has no declared path the first child path will be used */
  getPath?: () => string;
  label: string;
  featureFlag?: keyof Config | boolean;
  subMenu?: Array<Omit<MenuConfigItem, 'icon' | 'subMenu'>>;
};

export const getMenuConfig = ({
  organizationKey,
}: {
  organizationKey: string;
}): MenuConfigItem[] => {
  const config = getConfig();
  const menuConfig: MenuConfigItem[] = [
    {
      id: 'statistics',
      icon: {
        initial: ChartIcon,
        selected: ChartIcon,
      },
      label: getCopy('sender_portal__statistics'),
      subMenu: [
        {
          id: 'statisticsContent',
          getPath: () =>
            metricsGetPath({
              id: 'organizationRootContent',
              organizationKey,
            }),
          label: getCopy('sender_portal__statistics_content'),
        },
        {
          id: 'statisticsOther',
          getPath: () =>
            metricsGetPath({
              id: 'organizationRootOther',
              organizationKey,
            }),
          label: getCopy('sender_portal__statistics_other'),
        },
      ],
      featureFlag: 'sender_portal_enable_sender_analytics',
    },
    {
      id: 'senderInfo',
      icon: {
        initial: PhonebookIcon,
        selected: PhonebookFilledIcon,
      },
      getPath: () =>
        senderHomeGetPath({ id: 'organizationRoot', organizationKey }),
      label: getCopy('sender_portal__nav_sender_info'),
    },
    {
      id: 'campaigns',
      icon: {
        initial: StarIcon,
        selected: StarFilledIcon,
      },
      getPath: () => CAMPAIGNS_BASE_PATH,
      label: getCopy('sender_portal__campaigns'),
    },
    {
      id: 'settings',
      icon: {
        initial: SettingsIcon,
        selected: SettingsFilledIcon,
      },
      getPath: () =>
        teamManagementGetPath({ id: 'organization', organizationKey }),
      label: getCopy('sender_portal__team_management'),
      featureFlag: 'sender_portal_enable_backoffice',
    },
  ];

  const menuConfigFiltered = menuConfig.filter(({ featureFlag }) => {
    switch (typeof featureFlag) {
      case 'boolean':
        return featureFlag;
      case 'string':
        return config[featureFlag];
      case 'undefined':
        return true;
      default:
        return false;
    }
  });

  return menuConfigFiltered;
};

export const flattenMenuConfig = (
  menuConfig: MenuConfigItem[]
): Array<MenuConfigItem | NonNullable<MenuConfigItem['subMenu']>[0]> => {
  return menuConfig.flatMap(i => [i, ...(i.subMenu ?? [])]);
};
