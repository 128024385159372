import type { AppUrlParameters } from '@sender-portal-fe/util-shared/src/types/appUrlParameters';
import type { HeimdallQueryParameters } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import { ConfigUtil } from '@sender-portal-fe/util-shared/src/util/config';
import { AppUrlParametersUtil } from '@sender-portal-fe/util-shared/src/util/appUrlParameters';
import { CopyUtil } from '@sender-portal-fe/util-shared/src/util/copy';
import { HeimdallQueryParametersUtil } from '@sender-portal-fe/util-shared/src/util/heimdallQueryParameters';
import type { Copy, Config, GetCopyArguments } from '../types';

const { setCopy, getCopy, getCopyObj } = new CopyUtil<Copy, GetCopyArguments>();

const { setConfig, getConfig } = new ConfigUtil<Config>();

const { getAppUrlParameters, setAppUrlParameters } = new AppUrlParametersUtil();

const {
  getHeimdallQueryParameters,
  setHeimdallQueryParameters,
  clearHeimdallQueryParameters,
} = new HeimdallQueryParametersUtil();

export {
  getAppUrlParameters,
  getConfig,
  getCopy,
  getCopyObj,
  getHeimdallQueryParameters,
  clearHeimdallQueryParameters,
};

export const setAppOptions = ({
  copy,
  config,
  appUrlParameters,
  heimdallQueryParameters,
}: {
  copy: Copy;
  config: Config;
  appUrlParameters: AppUrlParameters;
  heimdallQueryParameters: HeimdallQueryParameters;
}): void => {
  setCopy(copy);
  setConfig(config);
  setAppUrlParameters(appUrlParameters);
  setHeimdallQueryParameters(heimdallQueryParameters);
};
