import { getAndDecodeOption } from '@kivra/sdk/common';
import type { AppUrlParameters } from '../types/appUrlParameters';

export function getAppUrlParameters(href: string): AppUrlParameters {
  return {
    locale: getAndDecodeOption('locale', href),
    prefersColorScheme: getPrefersColorScheme(
      getAndDecodeOption('prefers_color_scheme', href)
    ),
  };
}

function getPrefersColorScheme(scheme?: string): 'light' | 'dark' | undefined {
  switch (scheme) {
    case 'light':
    case 'dark':
      return scheme;
    default:
      return undefined;
  }
}
