import React, { useState } from 'react';
import {
  TopBar,
  useIsMediumScreenSize,
  HamburgerMenu,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import { useLoggedInUser } from '@sender-portal-fe/util-shared/src/login';
import { goTo } from '@sender-portal-fe/util-shared/src/routing/history';
import { getCopy } from '../../util/appOptions';
import { getRoute } from '../../routes/routes';
import { PopoverAccountMenu } from './PopoverAccountMenu/PopoverAccountMenu';

type Props = {
  setIsMenuOpen: (isOpen: boolean) => void;
  isMenuOpen: boolean;
};

export const PortalTopBar = ({
  isMenuOpen,
  setIsMenuOpen,
}: Props): React.JSX.Element => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(true);
  const loggedInUser = useLoggedInUser();
  const email = loggedInUser.email;

  const isMediumScreenSize = useIsMediumScreenSize();
  const isSmallScreenSize = useIsSmallScreenSize();

  return (
    <TopBar
      transparentUntilScroll={isNavDrawerOpen}
      logoText={isMediumScreenSize ? '' : 'Sender Portal'}
      hideLogo={isSmallScreenSize}
      logoTextPosition="bottom"
      alignLogo={isMediumScreenSize ? 'center' : 'left'}
      logoHref={getRoute('start')}
      onClickLogo={() => goTo(getRoute('start'))}
      startAdornment={
        isMediumScreenSize && (
          <HamburgerMenu
            isOpen={isMenuOpen}
            openText={getCopy('sender_portal__menu')}
            closeText={getCopy('sender_portal__general_close')}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
              setIsNavDrawerOpen(!isNavDrawerOpen);
            }}
          />
        )
      }
      endAdornment={
        email && (
          <PopoverAccountMenu
            displayName={loggedInUser.displayName || email}
            onClose={() => setIsMenuOpen(false)}
          />
        )
      }
    />
  );
};
