import type { AppUrlParameters } from '../types/appUrlParameters';

export class AppUrlParametersUtil {
  appUrlParameters: AppUrlParameters | null;

  constructor() {
    this.appUrlParameters = null;
  }

  getAppUrlParameters = (): AppUrlParameters => {
    if (!this.appUrlParameters) {
      throw new Error(
        'getAppUrlParameters used before AppUrlParametersUtil was initialized with appUrlParameters'
      );
    }
    return this.appUrlParameters;
  };

  setAppUrlParameters = (newAppUrlParameters: AppUrlParameters): void => {
    this.appUrlParameters = newAppUrlParameters;
  };
}
