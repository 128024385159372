// This file is copied from kivra_fe_sdk repo
/**
 * Specification for generating code verifier and code challenge
 * https://tools.ietf.org/html/rfc7636#section-4.1
 */

import type { LegitimateAny } from '@kivra/sdk/types/util/any';

const CODE_VERIFIER_LENGTH = 64;

export const pkceChallenge = async (): Promise<{
  codeVerifier: string;
  codeChallenge: string;
}> => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  return { codeVerifier, codeChallenge };
};

export const generateCodeVerifier = (): string => {
  const mask =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
  return randomString(CODE_VERIFIER_LENGTH, mask);
};

const randomString = (size: number, mask: string): string => {
  const byteLength = 256;
  const maskLength = mask.length;
  if (maskLength > byteLength) {
    throw new Error(`Mask longer than ${byteLength} is not supported`);
  }
  const randomValues = window.crypto.getRandomValues(new Uint8Array(size)); // 0 - 255
  return Array.from(randomValues, value => {
    const randomIndex = Math.floor(lerp(0, maskLength, value / byteLength));
    return mask[randomIndex];
  }).join('');
};

/**
 * linear interpolation/extrapolation
 * @param min bottom value of range
 * @param max top value of range
 * @param amount amount to interpolate 0-1, value outside of this range will result in extrapolation
 */
const lerp = (min: number, max: number, amount: number): number =>
  min * (1 - amount) + max * amount;

const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const hashed = await sha256(codeVerifier);
  const base64encoded = base64UrlEncode(hashed);
  return base64encoded;
};

const sha256 = (plain: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data).then(hash => {
    return String.fromCharCode.apply(
      null,
      new Uint8Array(hash) as LegitimateAny
    );
  });
};

export const base64UrlEncode = (plain: string): string => {
  return window
    .btoa(plain)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};
