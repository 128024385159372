import { setBasePath as setMetricsBasePath } from '@sender-portal-fe/module-metrics/routes';
import { setBasePath as setSenderHomeBasePath } from '@sender-portal-fe/module-sender-home/routes';
import { setBasePath as setTeamManagementBasePath } from '@sender-portal-fe/module-team-management/routes';
import { history } from '@sender-portal-fe/util-shared/src/routing/history';
import React from 'react';
import { Router as ReactRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';
import { getRoute } from './routes';

export type RouteParams = {
  organizationId?: string;
};

export const TEAM_MANAGEMENT_BASE_PATH = '/team-management';
export const SENDER_HOME_BASE_PATH = '/sender-home';
export const CAMPAIGNS_BASE_PATH = '/campaigns';
export const METRICS_BASE_PATH = '/metrics';

export const Router = (): React.JSX.Element => {
  setTeamManagementBasePath(TEAM_MANAGEMENT_BASE_PATH);
  setMetricsBasePath(METRICS_BASE_PATH);
  setSenderHomeBasePath(SENDER_HOME_BASE_PATH);
  return (
    <ReactRouter history={history}>
      <Switch>
        <Route
          path={[
            CAMPAIGNS_BASE_PATH,
            METRICS_BASE_PATH,
            SENDER_HOME_BASE_PATH,
            TEAM_MANAGEMENT_BASE_PATH,
            getRoute('start'),
          ]}
        >
          <PrivateRoutes />
        </Route>
        <Route path="*">
          <PublicRoutes />
        </Route>
      </Switch>
    </ReactRouter>
  );
};
