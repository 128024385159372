import type {
  Config as SenderAnalyticsConfig,
  Copy as SenderAnalyticsCopy,
} from '@sender-portal-fe/module-metrics/options';
import { getPath as senderAnalyticsGetPath } from '@sender-portal-fe/module-metrics/routes';
import type {
  Config as SenderInfoConfig,
  Copy as SenderInfoCopy,
} from '@sender-portal-fe/module-sender-home/options';
import { getPath as senderInfoGetPath } from '@sender-portal-fe/module-sender-home/routes';
import type {
  Config as BackofficeConfig,
  Copy as BackofficeCopy,
} from '@sender-portal-fe/module-team-management/options';
import { getPath as backofficeGetPath } from '@sender-portal-fe/module-team-management/routes';
import { goTo } from '@sender-portal-fe/util-shared/src/routing/history';
import type { AppOptions } from '@sender-portal-fe/util-shared/src/types/appOptions';
import React from 'react';
import { Route } from 'react-router-dom';
import { MountModule } from '../components/MountModule';
import { OnOrganizationSwap } from '../components/OnOrganizationSwap';
import { Campaigns } from '../pages/Campaigns';
import { DefaultPage } from '../pages/Default';
import { ModuleRoute } from './ModuleRoute';
import {
  CAMPAIGNS_BASE_PATH,
  METRICS_BASE_PATH,
  SENDER_HOME_BASE_PATH,
  TEAM_MANAGEMENT_BASE_PATH,
} from './Router';
import {
  getBackofficeOptions,
  getSenderAnalyticsOptions,
  getSenderInfoOptions,
} from './moduleOptions';
import { getRoute } from './routes';

export const ModuleRoutes = (): React.JSX.Element => {
  return (
    <>
      <Route path={getRoute('start')}>
        <DefaultPage />
      </Route>
      <ModuleRoute
        featureFlag="sender_portal_enable_sender_analytics"
        path={METRICS_BASE_PATH}
        key={METRICS_BASE_PATH}
      >
        <OnOrganizationSwap
          callback={organizationKey => {
            goTo(
              senderAnalyticsGetPath({
                id: 'organizationRootContent',
                organizationKey,
              })
            );
          }}
        >
          <MountModule<AppOptions<SenderAnalyticsCopy, SenderAnalyticsConfig>>
            moduleId="sender_analytics_root"
            asyncMount={() =>
              import('@sender-portal-fe/module-metrics').then(
                ({ mount }) => mount
              )
            }
            options={getSenderAnalyticsOptions()}
          />
        </OnOrganizationSwap>
      </ModuleRoute>
      <ModuleRoute
        featureFlag="sender_portal_enable_backoffice"
        path={TEAM_MANAGEMENT_BASE_PATH}
        key={TEAM_MANAGEMENT_BASE_PATH}
      >
        <OnOrganizationSwap
          callback={organizationKey => {
            goTo(
              backofficeGetPath({
                id: 'organization',
                organizationKey,
              })
            );
          }}
        >
          <MountModule<AppOptions<BackofficeCopy, BackofficeConfig>>
            moduleId="backoffice_root"
            asyncMount={() =>
              import('@sender-portal-fe/module-team-management').then(
                ({ mount }) => mount
              )
            }
            options={getBackofficeOptions()}
          />
        </OnOrganizationSwap>
      </ModuleRoute>
      <ModuleRoute path={SENDER_HOME_BASE_PATH}>
        <OnOrganizationSwap
          callback={organizationKey => {
            goTo(
              senderInfoGetPath({
                id: 'organizationRoot',
                organizationKey,
              })
            );
          }}
        >
          <MountModule<AppOptions<SenderInfoCopy, SenderInfoConfig>>
            moduleId="sender_info_root"
            asyncMount={() =>
              import('@sender-portal-fe/module-sender-home').then(
                ({ mount }) => mount
              )
            }
            options={getSenderInfoOptions()}
          />
        </OnOrganizationSwap>
      </ModuleRoute>
      <ModuleRoute path={CAMPAIGNS_BASE_PATH} key={CAMPAIGNS_BASE_PATH}>
        <Campaigns />
      </ModuleRoute>
    </>
  );
};
