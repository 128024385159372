import { Display, css } from '@kivra/react-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const ModuleHeading = ({ children }: Props): React.JSX.Element => (
  <Display size="medium" className={css({ marginBottom: '$spacing-8' })}>
    {children}
  </Display>
);
