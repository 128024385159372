import { createSynchronousStorage } from '@kivra/sdk/storage';
import { getPathSegmentAfter } from '@kivra/sdk/common';
import { history } from './history';

const organizationStorage = createSynchronousStorage<string>(
  'organization',
  () => localStorage
);
const senderStorage = createSynchronousStorage<string>(
  'sender',
  () => localStorage
);

export const getStoredOrganizationKey = (
  validOrganizationKeys: string[]
): string | undefined =>
  validOrganizationKeys.find(key => key === organizationStorage.get());

export const getStoredSenderKey = (
  validSenderKeys: string[]
): string | undefined =>
  validSenderKeys.find(key => key === senderStorage.get());

const storeOrganizationKey = (organizationKey: string): void => {
  senderStorage.remove();
  organizationStorage.set(organizationKey);
};

const storeSenderKey = (senderKey: string): void => {
  senderStorage.set(senderKey);
};

export class StorageWriter {
  organization: string | null;
  sender: string | null;
  unsubscribe;
  constructor() {
    this.unsubscribe = history.listen(() => this.listener());
    this.organization = organizationStorage.get();
    this.sender = senderStorage.get();
    this.listener();
  }

  listener(): void {
    const organization = getPathSegmentAfter(location.pathname, 'organization');
    const sender = getPathSegmentAfter(location.pathname, 'sender');
    if (organization && organization !== this.organization) {
      this.organization = organization;
      storeOrganizationKey(organization);
    }
    if (sender && sender !== this.sender) {
      this.sender = sender;
      storeSenderKey(sender);
    }
  }
}
