import { captureException } from '@kivra/sdk/log';
import { FullPageLoader, useOnMount } from '@kivra/react-components';
import { ZeroState } from '@sender-portal-fe/util-shared/src/components/ZeroState';
import type { mountModule } from '@sender-portal-fe/util-shared/src/types/mountModule';
import { getPortalError } from '@sender-portal-fe/util-shared/src/util/portalError';
import React, { useEffect, useState } from 'react';
import type { Root } from 'react-dom/client';
import { getCopy } from '../util/appOptions';

type Props<AppOptions> = {
  asyncMount: () => Promise<mountModule<AppOptions>>;
  LoadingComponent?: () => React.JSX.Element;
  ErrorComponent?: () => React.JSX.Element;
  options: AppOptions;
  moduleId: string;
};

export function MountModule<Options>({
  moduleId,
  asyncMount,
  ErrorComponent = () => <ZeroState {...getPortalError({ getCopy })} />,
  LoadingComponent = () => <FullPageLoader position="absolute" />,
  options,
}: Props<Options>): React.JSX.Element {
  const [status, setStatus] = useState<'loading' | 'error' | 'mounted'>(
    'loading'
  );
  const [root, setRoot] = useState<Root>();

  useOnMount(() => {
    asyncMount()
      .then(mount => {
        const mountedRoot = mount(moduleId, options);
        setRoot(mountedRoot);
        setStatus('mounted');
      })
      .catch((error: unknown) => {
        captureException(error);
        setStatus('error');
      });
  });

  useEffect(() => {
    return () => {
      /**
       * Use setTimeout to avoid React warning below:
       * Warning: Attempted to synchronously unmount a root while React
       * was already rendering. React cannot finish unmounting the root
       * until the current render has completed, which may lead to a
       * race condition.
       */
      setTimeout(() => {
        root?.unmount();
      });
    };
  }, [root]);

  return (
    <>
      {status === 'loading' && <LoadingComponent />}
      {status === 'error' && <ErrorComponent />}
      <div id={moduleId} />
    </>
  );
}
