import { getHeimdallQueryParameters } from '../sdk/authentication/heimdall';
import type { AppOptions } from '../types/appOptions';
import { getAppUrlParameters } from './urlParameters';
import { getConfig } from './config';
import { getLocalCopy } from './copy';

export async function getAppOptions<Copy, Config>(): Promise<
  AppOptions<Copy, Config>
> {
  const copy = await getLocalCopy<Copy>();
  const config = await getConfig<Config>();
  const appUrlParameters = getAppUrlParameters(window.location.href);
  const heimdallQueryParameters = getHeimdallQueryParameters(
    window.location.href
  );
  return {
    copy,
    config,
    appUrlParameters,
    heimdallQueryParameters,
  };
}
