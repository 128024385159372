import { updateRequestConfig } from '@kivra/sdk/common';
import { normalizeUrl } from '@sender-portal-fe/util-shared/src/bootstrap/normalizeUrl';
import { getConfig } from '../util/appOptions';
import { initSentry } from './sentry';

export function bootstrap(): void {
  const config = getConfig();
  initSentry(config);
  updateRequestConfig({ senderOrigin: config.sender_api_origin });
  normalizeUrl(window.location);
}
