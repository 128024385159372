import { RouteBuilder } from '@sender-portal-fe/util-shared/src/routing/RouteBuilder';

type ContainerRoutesConfig = { id: 'login' } | { id: 'start' } | { id: 'root' };

const containerRoutes = new RouteBuilder<ContainerRoutesConfig>({
  login: '/login',
  root: '/',
  start: '/start',
});

export const { getRoute, getPath } = containerRoutes;
