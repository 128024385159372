import type { Args } from './portalError';

export const getZeroStateProps = ({
  type,
  getCopy,
}: Omit<Args, 'fullpage'>): {
  pictogramIcon: 'broken-heart' | 'no-network' | 'lock';
  title: string;
  body: string;
} => {
  switch (type) {
    case 'generic':
      return {
        pictogramIcon: 'broken-heart',
        title: getCopy('error_generic__title'),
        body: getCopy('sender_portal__error_body_generic'),
      };
    case 'load-data':
      return {
        pictogramIcon: 'broken-heart',
        title: getCopy('sender_portal__error_title_load_content'),
        body: getCopy('sender_portal__error_body_generic'),
      };
    case 'no-network':
      return {
        pictogramIcon: 'no-network',
        title: getCopy('sender_portal__error_title_no_network'),
        body: getCopy('sender_portal__error_body_no_network'),
      };
    case 'not-found':
      return {
        pictogramIcon: 'broken-heart',
        title: getCopy('error__page_not_found'),
        body: getCopy('sender_portal__error_body_not_found'),
      };
    case 'access-denied':
      return {
        pictogramIcon: 'lock',
        title: getCopy('sender_backoffice__access_denied'),
        body: getCopy('sender_backoffice__access_denied_body'),
      };
    default:
      return window.navigator.onLine === false
        ? {
            pictogramIcon: 'no-network',
            title: getCopy('sender_portal__error_title_no_network'),
            body: getCopy('sender_portal__error_body_no_network'),
          }
        : {
            pictogramIcon: 'broken-heart',
            title: getCopy('sender_portal__error_title_load_content'),
            body: getCopy('sender_portal__error_body_generic'),
          };
  }
};
