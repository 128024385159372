import React from 'react';
import { BaseLayout } from '@sender-portal-fe/util-shared/src/components/layout/BaseLayout';
import { Body, Link, styled } from '@kivra/react-components';
import { ModuleHeading } from '@sender-portal-fe/util-shared/src/components/layout/ModuleHeading';
import { getConfig, getCopy } from '../util/appOptions';

export const Campaigns = (): React.JSX.Element => {
  const { kivra_campaigns_uri_origin } = getConfig();
  return (
    <BaseLayout data-test-id="campaigns-wrapper">
      <ModuleHeading>{getCopy('sender_portal__campaigns')}</ModuleHeading>
      <BodyWrapper>
        <Body size="medium" color="$text-secondary">
          {getCopy('sender_portal__campaigns_body_text')}
        </Body>
      </BodyWrapper>
      <Link
        buttonStyles={{ size: 'medium', variant: 'primary' }}
        to={kivra_campaigns_uri_origin}
      >
        {getCopy('sender_portal__campaigns_goto')}
      </Link>
    </BaseLayout>
  );
};

const BodyWrapper = styled.div({
  maxWidth: '560px',
  marginBottom: '$spacing-32',
});
