import type {
  Config as SenderAnalyticsConfig,
  Copy as SenderAnalyticsCopy,
} from '@sender-portal-fe/module-metrics/options';
import type {
  Config as SenderInfoConfig,
  Copy as SenderInfoCopy,
} from '@sender-portal-fe/module-sender-home/options';
import type {
  Config as BackofficeConfig,
  Copy as BackofficeCopy,
} from '@sender-portal-fe/module-team-management/options';
import type { AppOptions } from '@sender-portal-fe/util-shared/src/types/appOptions';
import {
  getAppUrlParameters,
  getConfig,
  getCopyObj,
  getHeimdallQueryParameters,
} from '../util/appOptions';

// TODO: Since we are using the same copy and config for all projects web can remove this?
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getOptions = () => ({
  copy: getCopyObj(),
  config: getConfig(),
  appUrlParameters: getAppUrlParameters(),
  heimdallQueryParameters: getHeimdallQueryParameters(),
});

export const getBackofficeOptions = (): AppOptions<
  BackofficeCopy,
  BackofficeConfig
> => getOptions();

export const getSenderInfoOptions = (): AppOptions<
  SenderInfoCopy,
  SenderInfoConfig
> => getOptions();

export const getSenderAnalyticsOptions = (): AppOptions<
  SenderAnalyticsCopy,
  SenderAnalyticsConfig
> => getOptions();
