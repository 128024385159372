import React from 'react';
import { Redirect } from 'react-router-dom';
import { getPath as getSenderHomePath } from '@sender-portal-fe/module-sender-home/routes';
import { getPath as getMetricsPath } from '@sender-portal-fe/module-metrics/routes';
import { useOrganizationState } from '../context/OrganizationProvider';
import { getConfig } from '../util/appOptions';

export const DefaultPage = (): React.JSX.Element => {
  const { organizationKey } = useOrganizationState();
  const { sender_portal_enable_sender_analytics } = getConfig();
  let defaultPath = getSenderHomePath({
    id: 'organizationRoot',
    organizationKey,
  });
  if (sender_portal_enable_sender_analytics) {
    defaultPath = getMetricsPath({
      id: 'organizationRootContent',
      organizationKey,
    });
  }
  return <Redirect to={defaultPath} />;
};
