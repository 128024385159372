import { Margin, MenuItem, Overline, styled } from '@kivra/react-components';
import React from 'react';
import { useOrganizationState } from '../../../context/OrganizationProvider';
import { getCopy } from '../../../util/appOptions';
import { OrganizationAvatar } from './OrganizationAvatar';

type Props = {
  onClick: () => void;
  autoFocus?: boolean;
};

export const OrganizationPicker = ({
  autoFocus,
  onClick,
}: Props): React.JSX.Element => {
  const { availableOrganizations, organizationKey, setOrganization } =
    useOrganizationState();

  const options = availableOrganizations.map(({ key, name }) => ({
    label: name,
    value: key,
  }));
  const lastIndex = options.length - 1;
  return (
    <>
      <Headline>{getCopy('sender_portal__organizations')}</Headline>
      {options.map(({ value, label }, index) => {
        const isSelected = organizationKey === value;
        return (
          <MenuItem
            selected={isSelected}
            key={value}
            title={label}
            divider={index === lastIndex}
            autoFocus={index === 0 && autoFocus}
            onClick={() => {
              setOrganization(value);
              onClick();
            }}
            size="small"
            iconSmall={() => (
              <Margin right={16}>
                <OrganizationAvatar isActive={isSelected} />
              </Margin>
            )}
          />
        );
      })}
    </>
  );
};

const Headline = styled(Overline)({
  fontWeight: 'bold',
  padding: ['$spacing-8', '$spacing-24'],
});
