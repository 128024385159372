export class ConfigUtil<Config extends Record<string, string | boolean>> {
  config: Config | null;

  constructor() {
    this.config = null;
  }

  getConfig = (): Config => {
    if (!this.config) {
      throw new Error(
        'getConfig used before ConfigUtil was initialized with config'
      );
    }
    return this.config;
  };

  setConfig = (newConfig: Config): void => {
    this.config = newConfig;
  };
}
