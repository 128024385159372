import React from 'react';
import { createRoot } from 'react-dom/client';
import { StorageWriter } from '@sender-portal-fe/util-shared/src/routing/storage';
import { SenderPortalErrorBoundary } from '@sender-portal-fe/util-shared/src/components/SenderPortalErrorBoundary';
import { getDefinedValue } from '@sender-portal-shared/sdk/src/assert-defined';
import { App } from './App';

function renderApp(): void {
  const rootElement = document.getElementById('root');
  const root = createRoot(getDefinedValue(rootElement));
  root.render(
    <SenderPortalErrorBoundary>
      <App />
    </SenderPortalErrorBoundary>
  );
}
new StorageWriter();
renderApp();
