import type { DistributiveOmit, FixThisAnyLater } from '@kivra/sdk/types/util';

export class RouteBuilder<Config extends { id: string }> {
  private _basePath: string;
  private _routes: Record<Config['id'], string>;

  constructor(routes: Record<Config['id'], string>) {
    this._basePath = '';
    this._routes = routes;
  }

  getBasePath = (): string => {
    return this._basePath;
  };

  getRoute = (id: Config['id']): string => {
    return this._basePath + this._routes[id];
  };

  getRoutes = (ids: Array<Config['id']>): string[] => {
    return ids.map(this.getRoute);
  };

  getPath = (routeArgs: Config): string => {
    const route = this.getRoute(routeArgs.id);
    return route
      .split('/')
      .map(segment => {
        if (segment.startsWith(':')) {
          // can  we make this type safe?
          const value = (routeArgs as FixThisAnyLater)[segment.substring(1)];
          if (!value) {
            throw new Error(
              `Could not assign ${segment} in route ${route} from ${JSON.stringify(
                routeArgs,
                undefined,
                2
              )}`
            );
          }
          return value;
        }
        return segment;
      })
      .join('/');
  };

  setBasePath = (basePath: string): void => {
    this._basePath = basePath;
  };

  /**
   *
   * @param base A subset of route parameters to be predefined. The resulting function will accept remaing parameters for any routes that require the base parameters.
   */
  createGetPath =
    <BaseParams extends DistributiveOmit<Config, 'id'>>(base?: BaseParams) =>
    (
      rest: DistributiveOmit<Extract<Config, BaseParams>, keyof BaseParams> & {
        id: string;
      }
    ) =>
      this.getPath({ ...base, ...rest } as FixThisAnyLater);
}
