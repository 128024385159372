import type { HeimdallToken } from '@kivra/sdk/authentication';
import type { Session } from '@kivra/sdk/identity';
import { userSession } from '@kivra/sdk/identity';

export const setTokenToSession = (token: HeimdallToken): void => {
  const session: Session = {
    accessToken: token.accessToken,
    userId: token.idToken,
    userType: 'non_user',
    expiryTime: Math.floor(
      (new Date().getTime() + token.expiresIn * 1000) / 1000
    ),
  };
  userSession.setSession(session);
};
